export function trackOutboundLink(url, eventLabel) {
  try {
    window.gtag("event", "click", {
      event_category: `outbound`,
      event_label: `${eventLabel ? `${eventLabel} - ` : ``}${url}`,
      transport_type: "beacon",
      event_callback: function () {
        console.log(`tracked outbound link ${url}`);
      },
    });
  } catch (e) {
    console.log({
      trackOutboundLink: `failed to track outbound link ${url}`,
      error: e,
    });
  }
}

export function trackEvent(
  eventType = "click",
  eventCategory = "click",
  eventLabel
) {
  try {
    window.gtag("event", eventType, {
      event_category: eventCategory,
      event_label: eventLabel,
      transport_type: "beacon",
      event_callback: function () {
        console.log(`tracked the event`, {
          type: eventType,
          category: eventCategory,
          label: eventLabel,
        });
      },
    });
  } catch (e) {
    console.log({
      trackOutboundLink: `failed to track outbound link ${{
        eventType,
        eventCategory,
        eventLabel,
      }}`,
      error: e,
    });
  }
}
