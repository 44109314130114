import React, { PureComponent } from "react";
import posed, { PoseGroup } from "react-pose";

import BreakpointListener from "components/BreakpointListener";
import Helmet from "react-helmet";
import InterstitialDialog from "components/InterstitialDialog";
import MainNav from "components/MainNav";
import { SkipNavLink } from "@reach/skip-nav";
import Snippets from "components/Snippets";
import WipeOverlay from "components/WipeOverlay";
import getTransitionKey from "utils/getTransitionKey";
import { sine } from "utils/easing";
import smoothScrollTo from "utils/smoothScrollTo";

const getPrimaryLoc = (location) => {
  let primaryLoc = location.split("/");
  primaryLoc.shift();
  if (primaryLoc.length) {
    return primaryLoc[0];
  }
  return null;
};

const shouldTransition = (location, prevLocation) => {
  if (!prevLocation) {
    // console.log('no prev loc');
    return true;
  }
  let prevPrimaryLoc = getPrimaryLoc(prevLocation);
  let currentPrimaryLoc = getPrimaryLoc(location);
  if (currentPrimaryLoc !== prevPrimaryLoc) {
    return true;
  }
  if (
    currentPrimaryLoc === "writers-books" &&
    prevPrimaryLoc === "writers-books"
  ) {
    return false;
  }

  if (
    location.indexOf("/winner/") > 0 ||
    location.indexOf("/finalist/") > 0 ||
    location.indexOf("/jury/") > 0 ||
    location.indexOf("/staff/") > 0 ||
    location.indexOf("/writer/") > 0 ||
    location.indexOf("/books/") > 0 ||
    location.indexOf("/guidelines") >= 0
  ) {
    return false;
  }
  if (
    prevLocation.indexOf("/winner/") > 0 ||
    prevLocation.indexOf("/finalist/") > 0 ||
    prevLocation.indexOf("/jury/") > 0 ||
    prevLocation.indexOf("/staff/") > 0 ||
    prevLocation.indexOf("/writer/") > 0 ||
    prevLocation.indexOf("/books/") > 0 ||
    prevLocation.indexOf("/guidelines") >= 0
  ) {
    return false;
  }
  return true;
};

const Transition = posed.div({
  preEnter: {
    x: -1,
  },
  exit: {
    x: -1,
    transition: {
      type: "tween",
      ease: sine.in,
    },
  },
  enter: {
    x: 0,
    beforeChildren: true,
    transition: {
      delay: 300,
      duration: 1,
      type: "tween",
      ease: sine.out,
    },
  },
});

class Layout extends PureComponent {
  first = true;
  state = { transition: false };
  dialog;

  static getDerivedStateFromProps(props, state) {
    // console.log('props loc', props.location, `state loc`, state.location);
    switch (true) {
      case state.location &&
        props.location.pathname.split &&
        props.location.pathname.split(`#`)[0] === state.location.pathname:
        if (
          props.location.state &&
          props.location.state.scrollToAnchor &&
          props.location.state.anchorId
        ) {
          smoothScrollTo(props.location.state.anchorId);
        }
        return null;
      case state.location !== undefined &&
        props.location.pathname !== state.location.pathname:
        let st = true;
        if (props.location.state) {
          st =
            (props.location.state.internal || props.location.state.modal) &&
            props.location.pathname.indexOf("/author") === -1
              ? false
              : true;
        }
        if (st) {
          st = shouldTransition(
            props.location.pathname,
            state.location ? state.location.pathname : null
          );
          // console.log("should transition", st);
        }
        return {
          prevPage: state.page,
          prevLocation: state.location,
          location: props.location,
          page: props.data ? props.data.page : null,
          transition: st,
        };
      default:
        return {
          prevPage: state.page,
          prevLocation: state.location,
          location: props.location,
          page: props.data ? props.data.page : null,
          transition: true,
        };
    }
  }

  getOverlayTheme(pathname) {
    switch (true) {
      case pathname.indexOf("/programs/") >= 0:
        return "red";
      case pathname.indexOf("/events/") >= 0:
        return "grey";
      case pathname.indexOf("/awards/") >= 0:
      default:
        return "black";
    }
  }

  componentDidMount() {
    this.revealTimeout = setTimeout(() => {
      let cover = document.getElementById("___cover");
      if (cover) {
        cover.style.backgroundColor = `rgba(23,23,23,0)`;
      }
      clearTimeout(this.revealTimeout);
      this.revealTimeout = setTimeout(() => {
        this.first = false;
        let cover = document.getElementById("___cover");
        if (cover) {
          cover.style.display = "none";
          document.body.removeChild(cover);
        }
      }, 405);
    }, 10);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearInterval(this.sInterval);
  }

  onPoseComplete = () => {
    clearTimeout(this.timeout);
    if (this.state.transition === true) {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, 250);

      clearTimeout(this.timeout);
      setTimeout(() => {
        this.setState({ transition: false });
      }, 500);
    }
  };

  render() {
    const { children, location, pageContext, data } = this.props;
    const transitionKey = getTransitionKey(location.pathname, "transition");
    // console.log("tk", transitionKey);
    const theme = this.getOverlayTheme(location.pathname);
    let snippets = null;
    // console.log({ data, pageContext });
    if (
      data.page &&
      data.page.data &&
      data.page.data.snippets &&
      data.page.data.snippets.length > 0
    ) {
      snippets = data.page.data.snippets
        .map(({ snippet }) => {
          return snippet.text || null;
        })
        .filter(Boolean);
    }

    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ class: "has-navbar-fixed-top" }} />
        <BreakpointListener />
        <WipeOverlay
          id="site-overlay"
          theme={theme}
          open={this.state.transition && !this.first}
          onPoseComplete={this.onPoseComplete}
          withParent={false}
          style={{
            zIndex: 1000,
            pointerEvents: `none`,
          }}
        />
        <SkipNavLink />
        <MainNav
          location={location}
          menu={
            pageContext.footerData
              ? pageContext.footerData.menu
              : data
              ? data.menu
              : null
          }
        />

        <PoseGroup
          animateOnMount={true}
          preEnterPose="preEnter"
          enterAfterExit={true}
          flipMove={false}
        >
          <Transition key={transitionKey}>
            <main>{children}</main>
          </Transition>
        </PoseGroup>

        <div id="dialog-root" />
        <div id="interstitial-dialog-root" />
        {snippets && <Snippets snippets={snippets.join("")} />}
        {(pageContext?.globals?.data?.dialog_enabled ||
          pageContext?.globals?.data?.show_interstitial_banner1?.toLowerCase() !==
            `disabled`) && (
          <InterstitialDialog
            id={pageContext?.globals?.data?.dialog_id}
            enabled={pageContext?.globals?.data?.dialog_enabled}
            showBanner={
              pageContext?.globals?.data?.show_interstitial_banner1?.toLowerCase() ===
              `only show banner`
                ? 2
                : pageContext?.globals?.data?.show_interstitial_banner1?.toLowerCase() ===
                  `when popup is dismissed`
                ? 1
                : 0
            }
            title={pageContext?.globals?.data?.dialog_title}
            content={pageContext?.globals?.data?.dialog_content}
            ctaLabel={pageContext?.globals?.data?.dialog_cta_label}
            ctaLink={pageContext?.globals?.data?.dialog_cta_link}
            bannerContent={
              pageContext?.globals?.data?.interstitial_banner_content
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default Layout;
