import React, { PureComponent } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalEmitter from "utils/GlobalEmitter";
import { Link } from "gatsby";
import Logo from "components/MainNav/Logo";
import Menu from "components/MainNav/Menu";
import Navbar from "react-bulma-components/lib/components/navbar";
import Search from "components/Search";
import VisuallyHidden from "@reach/visually-hidden";
import classNames from "classnames/bind";
import debounce from "utils/debounce";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import styles from "./MainNav.module.scss";

const cx = classNames.bind(styles);

class MainNav extends PureComponent {
  lastScrollY = 0;

  get rightMenu() {
    return document.querySelector(`.${styles.right}`);
  }

  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== `undefined` && window.innerWidth < 1200,
      menuShowing: false,
      searchShowing: false,
      isAtTop: true,
      isScrolling: false,
      location: props.location.pathname,
      willClose: false,
      disabled: false,
    };
    this.lastSize = null;
    if (typeof window !== `undefined`) {
      this.lastSize = window.innerWidth;
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("keyup", this.onKeyup);
    GlobalEmitter.on("resize", this.onResize);
    GlobalEmitter.on("disableNav", this.onDisableNav);
    GlobalEmitter.on("enableNav", this.onEnableNav);
    // BreakpointListener.on(events.breakpoint, this.onBreakpoint);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("keyup", this.onKeyup);
    GlobalEmitter.off("resize", this.onResize);
    GlobalEmitter.off("disableNav", this.onDisableNav);
    GlobalEmitter.off("enableNav", this.onEnableNav);
    clearTimeout(this.scrollTimeout);
    clearTimeout(this.menuTimeout);
    clearTimeout(this.closeTimeout);
    // BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.location) {
      return { willClose: true, location: props.location.pathname };
    }
    return { location: props.location.pathname };
  }

  onDisableNav = () => {
    this.setState({ disabled: true, menuShowing: false });
  };

  onEnableNav = () => {
    this.setState({ disabled: false });
  };

  onKeyup = (e) => {
    if (e.which === 27) {
      if (this.state.searchShowing) {
        this.hideSearch();
      }
      if (this.state.menuShowing) {
        this.hideMenu();
      }
    }
  };

  onResize = debounce(() => {
    if (this.state.isMobile && window.innerWidth >= 1200) {
      this.setState({ isMobile: false });
    } else if (!this.state.isMobile && window.innerWidth < 1200) {
      this.setState({ isMobile: true });
    }
  }, 250);

  onScroll = (e) => {
    if (
      this.state.disabled ||
      this.state.menuShowing ||
      this.state.searchShowing
    ) {
      return;
    }

    let isAtTop = window.scrollY === 0;
    let isScrolling = !isAtTop && window.scrollY < this.lastScrollY;

    this.setState({
      isAtTop,
      isScrolling,
    });

    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 4000);

    this.lastScrollY = window.scrollY;
  };

  toggleMenu = () => {
    let menuShowing = !this.state.menuShowing;
    this.setState({ menuShowing });
  };

  toggleSearch = () => {
    let searchShowing = !this.state.searchShowing;
    this.setState({ searchShowing });
  };

  showSearch = () => {
    let searchShowing = true;
    this.setState({ searchShowing });
  };

  hideSearch = () => {
    let searchShowing = false;
    this.setState({ searchShowing });
  };

  showMenu = () => {
    let menuShowing = true;
    this.setState({ menuShowing });
  };

  hideMenu = () => {
    let menuShowing = false;
    this.setState({ menuShowing });
  };

  componentDidUpdate() {
    clearTimeout(this.searchTimeout);
    if (this.state.willClose) {
      this.closeTimeout = setTimeout(() => {
        this.setState({ menuShowing: false, willClose: false });
      }, 750);
      return;
    }
    if (this.state.menuShowing) {
      if (!document.documentElement.classList.contains("menu-showing")) {
        document.documentElement.classList.add("menu-showing");
      }
      if (!document.body.classList.contains("menu-showing")) {
        document.body.classList.add("menu-showing");
      }
    } else {
      if (document.documentElement.classList.contains("menu-showing")) {
        document.body.classList.remove("menu-showing");
      }
      if (document.body.classList.contains("menu-showing")) {
        document.body.classList.remove("menu-showing");
      }
    }
    if (this.state.searchShowing) {
      if (!document.documentElement.classList.contains("search-showing")) {
        document.documentElement.classList.add("search-showing");
      }

      if (!document.body.classList.contains("search-showing")) {
        document.body.classList.add("search-showing");
      }
    } else {
      if (document.documentElement.classList.contains("search-showing")) {
        document.documentElement.classList.remove("search-showing");
      }
      if (document.body.classList.contains("search-showing")) {
        document.body.classList.remove("search-showing");
      }
    }
    clearTimeout(this.menuTimeout);

    if (this.state.menuShowing || this.state.searchShowing) {
      this.menuTimeout = setTimeout(() => {
        this.rightMenu.style.visibility = "hidden";
      }, 500);
    } else {
      this.rightMenu.style.visibility = "visible";
    }
  }

  render() {
    const { menu } = this.props;
    return (
      <React.Fragment>
        <header className={styles.header}>
          <Navbar
            fixed="top"
            aria-label="main navigation"
            className={cx({
              main: true,
              top: this.state.isAtTop,
              scrolling: this.state.isScrolling,
              menuShowing: this.state.menuShowing,
            })}
          >
            <Navbar.Brand
              className={cx({
                brand: true,
                scrolling: this.state.isScrolling,
                menuShowing: this.state.menuShowing,
                top: this.state.isAtTop,
              })}
            >
              <Navbar.Item renderAs="div" className={styles.logo}>
                <Link
                  to={`/`}
                  title={`Home`}
                  onClick={() => {
                    // this.hideMenu();
                    this.hideSearch();
                  }}
                >
                  <VisuallyHidden>Home</VisuallyHidden>
                  <Logo
                    title="Writers' Trust of Canada"
                    width={this.state.isMobile ? 100 : 160}
                    height={this.state.isMobile ? 28.125 : 45}
                  />
                </Link>
              </Navbar.Item>
            </Navbar.Brand>
            <Navbar.Container
              position="end"
              className={cx({
                right: true,
                scrolling: this.state.isScrolling,
                menuShowing: this.state.menuShowing,
                searchShowing: this.state.searchShowing,
                top: this.state.isAtTop,
              })}
            >
              <Navbar.Item
                renderAs="div"
                className={`navbar-item ${styles.navbarItem}`}
              >
                <a
                  href={`https://www.gifttool.com/donations/Donate?ID=2281&AID=4360&hjgJGJHGjhGjkhGKJuyt567765435EdGFCCbnjgut`}
                  title={`Donate`}
                >
                  Donate
                </a>
              </Navbar.Item>
              <Navbar.Item
                renderAs="div"
                aria-haspopup="true"
                aria-controls="menu"
                title={`Menu`}
                className={cx({
                  navbarItem: true,
                  menuItem: true,
                  burger: true,
                })}
              >
                <button aria-controls={`menu`} onClick={this.toggleMenu}>
                  {/* <span>Menu</span> */}
                  <VisuallyHidden>Menu</VisuallyHidden>
                  <i className="icon">
                    <span />
                    <span />
                    <span />
                  </i>
                </button>
              </Navbar.Item>
              <Navbar.Item
                renderAs="div"
                aria-haspopup="true"
                aria-controls="search"
                title={`Search`}
                className={styles.navbarItem}
              >
                <button aria-controls={`search`} onClick={this.toggleSearch}>
                  <span className="is-sr-only">Search</span>
                  <i className="icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </i>
                </button>
              </Navbar.Item>
            </Navbar.Container>
          </Navbar>
          <button
            onClick={this.state.menuShowing ? this.hideMenu : this.hideSearch}
            className={cx({
              close: true,
              menuShowing: this.state.menuShowing,
              searchShowing: this.state.searchShowing,
            })}
          >
            <span>Close</span>
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          </button>
        </header>

        <Menu
          styles={styles}
          data={menu.data}
          hideMenu={this.hideMenu}
          className={cx({
            menu: true,
          })}
          open={this.state.menuShowing === true}
          location={this.props.location}
          isMobile={this.state.isMobile}
        />
        <Search open={this.state.searchShowing} closeSearch={this.hideSearch} />
      </React.Fragment>
    );
  }
}

export default MainNav;
