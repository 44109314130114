import React from 'react';
import ReactDOM from 'react-dom';

function Snippets({ snippets }) {
  if (typeof window === `undefined`) {
    return null;
  }
  const domNode = document.getElementById(`snippets`);
  return ReactDOM.createPortal(
    <div dangerouslySetInnerHTML={{ __html: snippets }} />,
    domNode
  );
}

export default Snippets;
