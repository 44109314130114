import React, { Component } from "react";

import GlobalEmitter from "utils/GlobalEmitter";
import PropTypes from "prop-types";
import events from "utils/events";
import mitt from "mitt";
import styles from "./BreakpointListener.module.scss";

export default class BreakpointListener extends Component {
  static size = "fullhd";
  static gap = 22.5;

  static propTypes = {
    defaultBreakpoint: PropTypes.string,
  };

  static defaultProps = {
    defaultBreakpoint: "fullhd",
  };

  static emit(type, data) {
    if (!BreakpointListener.emitter) {
      BreakpointListener.emitter = mitt();
    }
    BreakpointListener.emitter.emit(type, data);
  }

  static on(type, cb) {
    if (!BreakpointListener.emitter) {
      BreakpointListener.emitter = mitt();
    }
    return BreakpointListener.emitter.on(type, cb);
  }

  static off(type, cb) {
    if (!BreakpointListener.emitter) {
      return;
    }
    return BreakpointListener.emitter.off(type, cb);
  }

  state = {
    breakpoint: null,
  };

  componentDidMount() {
    if (typeof window === `undefined`) {
      return;
    }
    GlobalEmitter.on(events.resize, this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.resize, this.onResize);
  }

  shouldComponentUpdate() {
    return false;
  }

  getBreakpointData = () => {
    if (typeof window === `undefined` || !this.div) {
      return this.props.defaultBreakpoint;
    }

    let size = window.getComputedStyle(this.div).content.replace(/"/g, "");
    let gap = parseFloat(
      window.getComputedStyle(this.div).columnGap.replace(/"/g, "")
    );

    BreakpointListener.gap = gap;
    BreakpointListener.size = size;

    return { size, gap };
  };

  onResize = () => {
    let bpData = this.getBreakpointData();
    let oldSize = this.state.breakpoint;
    let newSize = bpData.size;
    if (newSize === oldSize) {
      return;
    }
    this.setState({ breakpoint: newSize }, () => {
      BreakpointListener.emit(events.breakpoint, {
        oldSize,
        newSize,
        gap: bpData.gap,
      });
    });
  };

  render() {
    return (
      <div ref={(r) => (this.div = r)} className={styles.breakpointListener} />
    );
  }
}
