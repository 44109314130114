import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import PropTypes from 'prop-types';
import GlobalEmitter from 'utils/GlobalEmitter';
import events from 'utils/events';
import {
  shuffle,
  sortTlBr,
  sortBlTr,
  sortTrBl,
  sortBrTl,
  sortLtr,
  sortRtl,
  sortTb,
  sortBt
} from 'utils/array';
import classNames from 'classnames/bind';
import styles from './Triangles.module.scss';
import posed, { PoseGroup } from 'react-pose';
import uid from 'utils/uid';
import { sine } from 'utils/easing';

const cx = classNames.bind(styles);
const layouts = { 0: 'tl', 1: 'tr', 2: 'br', 3: 'bl' };

// const Tri = posed.i({
//   hidden: {
//     transform: `rotate3d(0.5, 0.866, 0, 90deg)`
//   },
//   show: {
//     transform: `rotate3d(-0.5, 0.866, 0, 0deg)`
//   }
// });

const rotationTransitionIn = {
  type: 'tween',
  ease: sine.out,
  duration: 250
};
const rotationTransitionOut = {
  type: 'tween',
  ease: sine.in,
  duration: 100
};

const Tri = posed.i({
  hidden: {
    opacity: 0,
    translateZ: 0,
    rotateX: 0.5 * 90,
    rotateY: 0.866 * 90,
    rotateZ: 0,
    transition: {
      opacity: {
        type: 'tween',
        ease: sine.in,
        duration: 50
      },
      rotateX: rotationTransitionOut,
      rotateY: rotationTransitionOut,
      rotateZ: rotationTransitionOut
    }
  },
  show: {
    opacity: 1,
    translateZ: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    transition: {
      opacity: {
        type: 'tween',
        ease: sine.out,
        duration: 100
      },
      rotateX: rotationTransitionIn,
      rotateY: rotationTransitionIn,
      rotateZ: rotationTransitionIn
    }
  }
});

const TriContainer = posed.figure({
  hidden: {
    opacity: 0,
    beforeChildren: true
  },
  hide: {
    opacity: 0,
    beforeChildren: true,
    transition: {
      type: 'tween',
      ease: sine.in,
      duration: 150,
      delay: 0
    }
  },
  show: {
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 20,
    transition: ({ delay = 0, revealDelay = 0 }) => ({
      type: 'tween',
      duration: 0,
      delay: delay + revealDelay
    })
  }
});

class Triangles extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    percentageChanceofNoTriangle: PropTypes.number,
    size: PropTypes.number,
    theme: PropTypes.oneOf(['red', 'black', 'white']),
    masks: PropTypes.shape({
      bl: PropTypes.arrayOf(
        PropTypes.shape({ cols: PropTypes.number, rows: PropTypes.number })
      ),
      br: PropTypes.arrayOf(
        PropTypes.shape({ cols: PropTypes.number, rows: PropTypes.number })
      ),
      tl: PropTypes.arrayOf(
        PropTypes.shape({ cols: PropTypes.number, rows: PropTypes.number })
      ),
      tr: PropTypes.arrayOf(
        PropTypes.shape({
          cols: PropTypes.number,
          rows: PropTypes.number
        })
      )
    }),
    animationType: PropTypes.oneOf([
      `random`,
      `tb`,
      `bt`,
      `ltr`,
      `rtl`,
      `bltr`,
      `trbl`,
      `tlbr`,
      `brtl`
    ]),
    autoReveal: PropTypes.bool,
    revealDelay: PropTypes.number
  };

  static defaultProps = {
    debug: false,
    percentageChanceofNoTriangle: 70,
    size: 28,
    masks: null,
    animationType: `brtl`,
    theme: `white`,
    autoReveal: true,
    revealDelay: 1,
    revealed: false
  };

  static INDEX = 0;

  static getDerivedStateFromProps(props, state) {
    if (!props.autoReveal && props.revealed !== state.revealed) {
      return { willReveal: props.revealed };
    }
    return null;
  }

  get base() {
    if (typeof window === `undefined`) {
      return null;
    }
    return this.main;
  }

  get bounds() {
    if (!this.base) {
      return null;
    }
    if (!this.__bounds) {
      this.__bounds = this.base.getBoundingClientRect();
    }
    return this.__bounds;
  }

  get gridArray() {
    if (!this.grid) {
      return [];
    }
    if (!this.__gridArray) {
      this.__gridArray = Object.keys(this.grid).map(key => this.grid[key]);
    }
    return this.__gridArray;
  }

  createSlot(i, j) {
    let box;
    let { size } = this.props;
    // add 2x2 box
    if (
      this.boxes.filter(box => box.slots[0] === `${i}_${j}`).length === 0 &&
      i % 2 === 0 &&
      j % 2 === 0
    ) {
      box = {
        available: true,
        occupied: false,
        id: this.boxes.length,
        on: true,
        x: j * size,
        y: i * size,
        // tl, tr, br, bl
        slots: [
          `${i}_${j}`,
          `${i}_${j + 1}`,
          `${i + 1}_${j + 1}`,
          `${i + 1}_${j}`
        ]
      };
      this.boxes.push(box);
    }
    let slot = this.grid[`${i}_${j}`];
    if (!slot) {
      // add slot if none existed before
      slot = {
        id: `${i}_${j}`,
        x: j * size,
        y: i * size,
        row: i,
        col: j,
        available: true,
        occupied: false,
        tri: null,
        on: true,
        box: null,
        revealed: false
      };
      this.grid[`${i}_${j}`] = slot;
    } else {
      // turn on slot that was already created
      slot.on = true;
    }
    slot.box = box;
  }

  addTrMask(mask, rows, cols) {
    let slot;
    let maskRows = mask.rows;
    let maskCols = mask.cols;
    if (maskRows < 0) {
      maskRows = rows + maskRows;
    }
    if (maskCols < 0) {
      maskCols = cols + maskCols;
    }
    for (let i = 0; i < maskRows; i++) {
      for (let j = cols - 1; j > cols - 1 - maskCols; j--) {
        slot = this.grid[`${i}_${j}`];
        if (!slot) continue;
        slot.on = false;
        slot.available = false;
        if (slot.box) {
          slot.box.on = false;
          slot.box.available = false;
        }
      }
    }
  }

  addBlMask(mask, rows, cols) {
    let slot;
    let maskRows = mask.rows;
    let maskCols = mask.cols;
    if (maskRows < 0) {
      maskRows = rows + maskRows;
    }
    if (maskCols < 0) {
      maskCols = cols + maskCols;
    }
    for (let i = rows - 1; i > rows - 1 - maskRows; i--) {
      for (let j = 0; j < maskCols; j++) {
        slot = this.grid[`${i}_${j}`];
        if (!slot) continue;
        slot.on = false;
        slot.available = false;
        if (slot.box) {
          slot.box.on = false;
          slot.box.available = false;
        }
      }
    }
  }

  generateGrid() {
    if (!this.base) {
      return null;
    }

    // invalidate array representation of grid
    this.__gridArray = null;
    this.boxes = this.boxes || [];

    let { size, masks } = this.props;
    let cols = Math.floor(this.width / size);
    let rows = Math.floor(this.height / size);
    this.grid.rows = rows;
    this.grid.cols = cols;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        this.createSlot(i, j);
      }
    }

    // add masks
    if (masks) {
      if (masks.bl) {
        masks.bl.forEach(mask => this.addBlMask(mask, rows, cols));
      }
      if (masks.tr) {
        masks.tr.forEach(mask => this.addTrMask(mask, rows, cols));
      }
    }
    // create grid array while turning off unneeded slots
    this.gridArray.forEach(tile => {
      if (tile.row > rows - 1 || tile.col > cols - 1) {
        tile.on = false;
      }
    });

    // add triangle within each box
    this.boxes
      .filter(box => box.available)
      .forEach(box => {
        let gridSlot;
        if (box.available) {
          if (
            Math.random() * 100 >
            100 - this.props.percentageChanceofNoTriangle
          ) {
            box.available = false;
          }
          for (let i = 0; i < 4; i++) {
            gridSlot = this.grid[box.slots[i]];
            if (gridSlot && !gridSlot.available) {
              box.available = false;
              break;
            }
          }
        }

        if (!box.available) {
          return;
        }

        let slotNum = Math.floor(Math.random() * 4);
        let layout = layouts[slotNum];
        let id = box.slots[slotNum];

        gridSlot = this.grid[id];

        if (!gridSlot) {
          return;
        }

        // make all slots in box unavailable
        box.slots.forEach(slot => {
          let gridSlot = this.grid[slot];
          if (gridSlot) {
            gridSlot.available = false;
          }
        });

        gridSlot.occupied = true;
        box.occupied = true;
        gridSlot.layout = layout;
      });

    return this.grid;
  }

  get width() {
    if (!this.bounds) {
      return 0;
    }
    return this.bounds.width;
  }

  get height() {
    if (!this.base) {
      return 0;
    }
    return this.bounds.height;
  }

  get triangleStyle() {
    // cache style
    if (!this.__triangleStyle) {
      const { size } = this.props;
      const styleSize = `${(size - 4) / 15}rem`;
      this.__triangleStyle = { borderWidth: `${styleSize} 0 0 ${styleSize}` };
    }
    return this.__triangleStyle;
  }

  constructor(props) {
    super(props);
    Triangles.INDEX++;
    this.grid = {};
    this.id = `triangles-${Triangles.INDEX}`;

    this.state = {
      waypointDisabled: false,
      revealed: false,
      isTransitioning: false
    };
  }

  onResize = () => {
    if (!this.state.revealed || this.state.isTransitioning) {
      return;
    }
    clearTimeout(this.revealTimeout);

    if (this.state.revealed) {
      this.setState({ revealed: false, isTransitioning: true }, () => {
        this.__triangleStyle = null;
        this.__bounds = null;
        this.revealTimeout = setTimeout(() => {
          this.generateGrid();
          this.reveal();
        }, 1000);
      });
    }
  };

  onWaypointEnter = () => {
    if (!this.props.autoReveal) {
      return;
    }

    if (this.hasEntered) {
      return;
    }
    this.reveal(true);

    this.__bounds = null;
  };

  reveal(disableWaypoint = false) {
    clearTimeout(this.revealTimeout);
    this.__triangleStyle = null;
    this.__bounds = null;
    this.revealTimeout = setTimeout(() => {
      let state = {
        grid: this.generateGrid(),
        revealed: true,
        willReveal: null,
        isTransitioning: false
      };
      if (disableWaypoint) {
        state.waypointDisabled = true;
      }
      this.setState(state);
      this.hasEntered = true;
    }, 2);
  }

  componentDidMount() {
    GlobalEmitter.off(events.resize, this.onResize);
    GlobalEmitter.on(events.resize, this.onResize);
    this.generateGrid();

    if (!this.autoReveal && this.props.revealed) {
      this.reveal();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.revealTimeout);
    GlobalEmitter.off(events.resize, this.onResize);
  }

  componentDidUpdate() {
    if (this.state.willReveal === true) {
      this.__bounds = null;
      this.reveal();
      return;
    } else if (this.state.willReveal === false) {
      this.setState({ revealed: false, willReveal: null }, () => {
        this.__triangleStyle = null;
        this.__bounds = null;
      });
    }
    if (!this.state.revealed) {
      return;
    }

    let gridItems = this.gridArray
      ? this.gridArray.filter(item => item.occupied && item.on)
      : [];
    gridItems.forEach(item => {
      item.revealed = true;
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.revealed !== this.state.revealed ||
      nextState.willReveal !== this.state.willReveal
    );
  }

  render() {
    const { revealed } = this.state;
    const { debug, animationType, size, theme } = this.props;
    const { cols, rows } = this.grid;
    let gridItems = this.gridArray
      ? this.gridArray.filter(item => item.occupied && item.on)
      : [];

    let onItems =
      debug && this.gridArray ? this.gridArray.filter(item => item.on) : [];
    let boxes = debug && this.boxes ? this.boxes.filter(item => item.on) : [];

    if (!revealed) {
      switch (animationType) {
        case 'tb':
          gridItems.sort(sortTb);
          break;
        case 'bt':
          gridItems.sort(sortBt);
          break;
        case 'ltr':
          gridItems.sort(sortLtr);
          break;
        case 'rtl':
          gridItems.sort(sortRtl);
          break;
        case 'bltr':
          gridItems.sort(sortBlTr({ x: 0, y: rows }));
          break;
        case 'trbl':
          gridItems.sort(sortTrBl({ x: cols, y: 0 }));
          break;
        case 'tlbr':
          gridItems.sort(sortTlBr({ x: 0, y: 0 }));
          break;
        case 'brtl':
          gridItems.sort(sortBrTl({ x: cols, y: rows }));
          break;
        case 'random':
        default:
          gridItems.sort(shuffle);
          break;
      }
      if (animationType !== 'random') {
        gridItems.reverse();
      }
    }

    // console.log(this.id, this.props.revealDelay, this.state.revealed);

    return (
      <Waypoint
        onEnter={this.state.waypointDisabled ? undefined : this.onWaypointEnter}
        key={this.id}
        bottomOffset={`10%`}
        topOffset={`-10%`}
      >
        <div id={this.id} ref={d => (this.main = d)} className={styles.wrapper}>
          {/* DEBUG  GRID */}
          {debug &&
            onItems.map((item, idx) => {
              return (
                <figure
                  key={`${this.id}-debug-${idx}`}
                  className={styles.tile}
                  style={{
                    width: size,
                    height: size,
                    top: item.y,
                    left: item.x
                  }}
                />
              );
            })}
          {debug &&
            boxes.map((box, idx) => {
              return (
                <figure
                  key={`${this.id}-debug-box-${idx}`}
                  className={styles.box}
                  style={{
                    width: size * 2,
                    height: size * 2,
                    top: box.y,
                    left: box.x,
                    backgroundColor: `#${Math.random()
                      .toString(16)
                      .slice(2, 8)}`
                  }}
                />
              );
            })}
          {/* END DEBUG  GRID */}
          <PoseGroup
            preEnterPose={`hidden`}
            enterPose={`show`}
            exitPose={`hide`}
          >
            {revealed ? (
              <TriContainer
                initialPose={`hidden`}
                key={`tricontainer-${uid()}`}
                revealDelay={this.props.revealDelay || 0}
              >
                {gridItems.map((item, idx) => {
                  return (
                    <figure
                      key={`${this.id}-${idx}`}
                      className={cx({
                        triangleWrapper: true,
                        [item.layout]: true
                      })}
                      style={{
                        width: size,
                        height: size,
                        top: item.y,
                        left: item.x
                      }}
                    >
                      <Tri
                        initialPose="hidden"
                        style={{ ...this.triangleStyle }}
                        className={cx({
                          triangle: true,
                          [theme]: true
                        })}
                      />
                    </figure>
                  );
                })}
              </TriContainer>
            ) : null}
          </PoseGroup>
        </div>
      </Waypoint>
    );
  }
}

export default Triangles;
