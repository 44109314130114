import linkLookup from "../../link-lookup.json";

let linkLookupArray = Object.values(linkLookup);

export default (id) => {
  return linkLookup[id];
};

export function getSlugByUID(uid) {
  let link = linkLookupArray.find((item) => item.uid === uid);
  if (link && link.slug) {
    return link.slug;
  }
  return null;
}
