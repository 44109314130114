import React from 'react';
import posed from 'react-pose';
import { sine } from 'utils/easing';

import classNames from 'classnames/bind';
import styles from './WipeOverlay.module.scss';

const cx = classNames.bind(styles);

const BG = posed.figure({
  closed: {
    applyAtEnd: {
      visibility: 'hidden'
    },
    opacity: 0,
    transition: {
      opacity: {
        type: 'tween',
        duration: 200,
        ease: sine.in
      }
    }
  },
  open: {
    applyAtStart: {
      visibility: 'visible'
    },
    opacity: 1,
    transition: {
      opacity: {
        type: 'tween',
        duration: 250,
        ease: sine.out
      }
    }
  }
});

export default ({ id = null, open, theme, style = null, ...props }) => {
  return (
    <BG
      id={id}
      className={cx({ bg: true, [theme]: true })}
      initialPose={'closed'}
      pose={open ? 'open' : 'closed'}
      withParent={props.withParent || false}
      onPoseComplete={props.onPoseComplete}
      style={style}
    />
  );
};
