import React, { Component } from 'react';
import posed from 'react-pose';
import Downshift from 'downshift';
import styles from './Search.module.scss';
import classNames from 'classnames/bind';
import WipeOverlay from 'components/WipeOverlay';
import { Link } from 'gatsby';
import lunr from 'lunr';
import searchIndexJson from '../../../search-index.json';
import { sine } from 'utils/easing';

const cx = classNames.bind(styles);

const config = {
  hide: {
    opacity: 0,
    beforeChildren: false,
    transition: {
      duration: 250,
      ease: sine.in,
      type: 'tween'
    }
  },
  show: {
    opacity: 1,
    beforeChildren: true,
    transition: { duration: 0, type: 'tween' },
    staggerChildren: 50
  }
};

const SearchContainer = posed.div(config);

const ddConfig = {
  hide: {
    opacity: 0,
    y: 30,
    transition: {
      y: {
        type: 'tween',
        delay: 300
      }
    }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 300,
      ease: sine.out,
      delay: 200
    }
  }
};

const AnimatedDropdown = posed.div(ddConfig);

// const listItemConfig = {
//   listItemPreEnter: {
//     opacity: 0,
//     y: 15
//   },
//   listItemExit: {
//     opacity: 0,
//     y: 0,
//     transition: {
//       type: 'tween',
//       duration: 150,
//       ease: sine.in
//     }
//   },
//   listItemEnter: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       type: 'tween',
//       duration: 200,
//       ease: sine.out
//     }
//   }
// };

// const ListItem = posed.li(listItemConfig);

const downcaser = token => {
  return token.update(str => {
    return str.toLowerCase();
  });
};

class Input extends Component {
  render() {
    return (
      <input
        ref={r => this.props.setRef(r)}
        className={cx({ input: true })}
        {...this.props.inputProps}
      />
    );
  }
}

class Search extends Component {
  lunrIndex = null;
  input = null;
  focused = false;

  static getDerivedStateFromProps(props, state) {
    if (state.open !== props.open) {
      return { open: props.open };
    }
    return null;
  }

  get base() {
    return document.getElementById('search');
  }

  handleStateChange = changes => {
    if (!this.indexInitted) {
      return;
    }
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ value: changes.selectedItem });
    } else if (changes.hasOwnProperty('inputValue')) {
      const searchResults = this.search(changes.inputValue) || [];
      this.setState({ value: changes.inputValue, searchResults });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: '',
      searchResults: []
    };
  }

  initIndex() {
    lunr.Pipeline.registerFunction(downcaser, 'downCaser');

    this.lunrIndex = lunr(function() {
      this.ref('id');
      this.field('title');
      // this.field("type");
      this.field('lastName');
      this.field('lastNames');
      this.field('authorUrl');

      searchIndexJson.forEach(function(doc) {
        this.add(doc);
      }, this);
    });

    this.indexInitted = true;
  }

  componentDidUpdate() {
    clearTimeout(this.visibleTimeout);

    if (this.state.open) {
      if (!this.indexInitted) {
        setTimeout(() => {
          this.initIndex();
        }, 1000);
      }

      this.base.style.visibility = 'visible';
      if (this.input) {
        if (!this.focused) {
          this.input.focus();
          this.input.setSelectionRange(
            0,
            this.input.value && this.input.value.length
              ? this.input.value.length
              : 0
          );
          this.focused = true;
        }
      }
    } else {
      setTimeout(() => {
        this.base.style.visibility = 'hidden';
        if (this.input) {
          this.input.blur();
          this.focused = false;
        }
      }, 500);
    }
  }

  search = value => {
    if (!value || value === '') {
      return null;
    }
    if (!this.lunrIndex) {
      return null;
    }
    const result = this.lunrIndex.search(`${value}`);
    let results;
    if (result.length) {
      results = result.map(r => searchIndexJson[parseInt(r.ref)]);
    }
    return results;
  };

  setRef = input => {
    this.input = input;
  };

  render() {
    const { value } = this.state;
    return (
      <SearchContainer
        id="search"
        style={{ visibility: 'hidden' }}
        className={cx({ search: true, active: this.state.open === true })}
        initialPose="hide"
        pose={this.state.open ? `show` : `hide`}
      >
        <WipeOverlay theme="grey" open={this.state.open === true} />
        <Downshift selectedItem={value} onStateChange={this.handleStateChange}>
          {({ getInputProps }) => (
            <div className={styles.downshift}>
              <AnimatedDropdown>
                <Input
                  setRef={this.setRef}
                  inputProps={getInputProps({
                    placeholder: 'Search'
                  })}
                />
              </AnimatedDropdown>
              <ul className={styles.results}>
                {/* <PoseGroup
                  animateOnMount={true}
                  preEnterPose={'listItemPreEnter'}
                  enterPose={'listItemEnter'}
                  exitPose={'listItemExit'}
                > */}
                {this.state.searchResults.map((r, idx) => (
                  <li key={`s-result-${idx}`} data-key={`s-result-${idx}`}>
                    <Link
                      to={`/${r.url}`}
                      title={r.title}
                      className={styles.item}
                      onClick={this.props.closeSearch}
                    >
                      <h4>{r.type}</h4>
                      <h3>
                        <span>{r.title}</span>
                        {r.authorName && (
                          <React.Fragment>
                            {' '}
                            by <span>{r.authorName}</span>
                          </React.Fragment>
                        )}
                      </h3>
                    </Link>
                  </li>
                ))}
                {/* </PoseGroup> */}
              </ul>
            </div>
          )}
        </Downshift>
      </SearchContainer>
    );
  }
}

export default Search;
