import { trackEvent, trackOutboundLink } from "utils/outboundClick";

import Container from "react-bulma-components/lib/components/container";
import Dialog from "react-a11y-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Triangles from "components/Triangles";
import classNames from "classnames/bind";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { getFromLocalStorage } from "utils/ls";
import styles from "./InterstitialDialog.module.scss";

const cx = classNames.bind(styles);

function Banner({ id, open, content, ctaLabel, ctaLink, closeBanner }) {
  return (
    <aside className={cx({ banner: true, open })}>
      <figure aria-hidden="true" className={styles.btriangles}>
        <Triangles
          key={`${id}-triangles-banner`}
          animationType={`bt`}
          theme="black"
          autoReveal={false}
          revealed={open === true}
          revealDelay={1000}
          size={15}
          percentageChanceofNoTriangle={10}
          masks={{ tr: [{ rows: 1, cols: 5 }] }}
        />
      </figure>
      <Container>
        <div className={styles.content}>
          <article dangerouslySetInnerHTML={{ __html: content.html }} />
          {ctaLink && ctaLabel && (
            <a
              onClick={() =>
                trackOutboundLink(ctaLink.url, "Banner CTA Button")
              }
              className={styles.button}
              href={ctaLink.url}
              target={ctaLink?.target}
            >
              {ctaLabel}
            </a>
          )}
        </div>
      </Container>
      <button className={styles.close} onClick={closeBanner}>
        <i className="icon">
          <FontAwesomeIcon icon={faTimes} />
        </i>
      </button>
    </aside>
  );
}

function InterstitialDialog({
  id = `interstitial-dialog`,
  enabled,
  showBanner,
  title,
  content,
  ctaLabel,
  ctaLink,
  bannerContent,
}) {
  // const isAvailable = getFromLocalStorage(`seen-dialog-${id}`) !== true;
  const first = React.useRef(true);
  const popupHidden = React.useRef(false);
  const bannerShown = React.useRef(false);
  const bannerHidden = React.useRef(false);
  const [open, setOpen] = React.useState(false);
  const [interstitialOpen, setInterstitialOpen] = React.useState(false);
  const dialogRef = React.useRef();
  const overlayRef = React.useRef();
  const dialogTimer = React.useRef();
  const showDialog = () => {
    if (dialogRef.current) {
      setOpen(true);
    }
  };
  const hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    if (dialogRef.current) {
      if (!popupHidden.current) {
        trackEvent("hide", "interstitial", "popup");
        popupHidden.current = true;
      }

      try {
        overlayRef.current.removeEventListener("click", hideDialog);
        dialogRef.current.off("hide", hideDialog);
      } catch (e) {}
    }
    setOpen(false);
  };

  const closeBanner = () => {
    setInterstitialOpen(false);
    if (!bannerHidden) {
      trackEvent("hide", "interstitial", "banner");
      bannerHidden.current = false;
    }
  };

  React.useEffect(() => {
    if (!overlayRef.current && dialogRef.current) {
      overlayRef.current = dialogRef.current.dialog.parentNode.querySelector(
        ".dialog-overlay"
      );
    }
    if (open && dialogRef.current) {
      overlayRef.current.style.display = `block`;
      dialogRef.current.show();
      if (first.current) {
        trackEvent("show", "interstitial", "popup");
      }
      first.current = false;
    } else if (first.current && showBanner === 2) {
      setTimeout(() => setInterstitialOpen(true), 5000);
      if (!bannerShown.current) {
        trackEvent("show", "interstitial", "banner");
        bannerShown.current = true;
      }
    } else if (!open && dialogRef.current) {
      overlayRef.current.style.display = `none`;
      dialogRef.current.hide();
      if (!first.current && !interstitialOpen) {
        setTimeout(() => setInterstitialOpen(true), 2000);
        if (!bannerShown.current) {
          trackEvent("show", "interstitial", "banner");
          bannerShown.current = true;
        }
      }
    }
  }, [open, dialogRef.current]);

  React.useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.addEventListener("click", hideDialog);
    }
    return () => {
      if (overlayRef.current) {
        overlayRef.current.removeEventListener("click", hideDialog);
      }
    };
  }, [overlayRef.current]);

  React.useEffect(() => {
    if (dialogRef.current) {
      overlayRef.current = dialogRef.current.dialog.parentNode.querySelector(
        ".dialog-overlay"
      );
      dialogRef.current.on("hide", hideDialog);
    }
    return () => {
      if (!dialogRef.current) {
        return;
      }
      try {
        dialogRef.current.off("hide", hideDialog);
      } catch (e) {}
    };
  }, [dialogRef.current]);

  React.useEffect(() => {
    if (enabled && first.current) {
      dialogTimer.current = setTimeout(showDialog, 3000);
    }
  }, [enabled]);

  return (
    <>
      <Dialog
        id="interstitial-dialog"
        appRoot="#__gatsby"
        dialogRoot="#interstitial-dialog-root"
        dialogRef={(dialog) => {
          dialogRef.current = dialog;
        }}
        title={title?.text || ``}
        closeButtonContent={
          <i className="icon">
            <FontAwesomeIcon icon={faTimes} />
          </i>
        }
        classNames={{
          element: "dialog-content dialog-content-interstitial",
          overlay: "dialog-overlay",
          base: "dialog",
          closeButton: "dialog-close",
        }}
      >
        <figure aria-hidden="true" className={styles.triangles}>
          <Triangles
            key={`${id}-triangles`}
            animationType={`brtl`}
            theme="red"
            autoReveal={false}
            revealed={open === true}
            revealDelay={1000}
            percentageChanceofNoTriangle={20}
          />
        </figure>
        {content?.html && (
          <div dangerouslySetInnerHTML={{ __html: content?.html }} />
        )}
        {ctaLink && ctaLabel && (
          <a
            onClick={() => trackOutboundLink(ctaLink.url, "Popup CTA Button")}
            href={ctaLink.url}
            target={ctaLink?.target}
            className="button"
          >
            {ctaLabel}
          </a>
        )}
      </Dialog>
      {showBanner > 0 && (
        <Banner
          open={interstitialOpen}
          content={bannerContent}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          closeBanner={closeBanner}
        />
      )}
    </>
  );
}

export default InterstitialDialog;
