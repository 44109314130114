import distanceBetween from './distanceBetween';

const shuffle = () => {
  return Math.random() > 0.5 ? -1 : 1;
};

const sortLtr = (a, b) => {
  return a.col - b.col;
};

const sortRtl = (a, b) => {
  return b.col - a.col;
};

const sortTb = (a, b) => {
  return a.row - b.row;
};

const sortBt = (a, b) => {
  return b.row - a.row;
};

const sortTlBr = pt => (a, b) => {
  let dist1 = distanceBetween(a.col, a.row, pt.x, pt.y);
  let dist2 = distanceBetween(b.col, b.row, pt.x, pt.y);
  if (dist1 === dist2) return a.col - b.col;
  return dist1 - dist2;
};

const sortTrBl = pt => (a, b) => {
  let dist1 = distanceBetween(a.col, a.row, pt.x, pt.y);
  let dist2 = distanceBetween(b.col, b.row, pt.x, pt.y);
  if (dist1 === dist2) return b.col - a.col;
  return dist1 - dist2;
};

const sortBlTr = pt => (a, b) => {
  let dist1 = distanceBetween(a.col, a.row, pt.x, pt.y);
  let dist2 = distanceBetween(b.col, b.row, pt.x, pt.y);
  if (dist1 === dist2) return a.col - b.col;
  return dist1 - dist2;
};

const sortBrTl = pt => (a, b) => {
  let dist1 = distanceBetween(a.col, a.row, pt.x, pt.y);
  let dist2 = distanceBetween(b.col, b.row, pt.x, pt.y);
  if (dist1 === dist2) return b.col - a.col;
  return dist1 - dist2;
};

export {
  shuffle,
  sortTlBr,
  sortBlTr,
  sortTrBl,
  sortBrTl,
  sortLtr,
  sortRtl,
  sortTb,
  sortBt
};
