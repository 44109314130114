export default (pathname, suffix = "") => {
  if (pathname.charAt(pathname.length - 1) === "/") {
    pathname = pathname.substr(0, pathname.length - 1);
  }
  let aPathname = pathname.split("/");
  let result = "";
  // console.log("gtk", pathname, aPathname[aPathname.length - 1]);
  switch (true) {
    case aPathname.indexOf("writers-books") >= 0:
      result = "writers-books";
      break;
    case aPathname.indexOf("recommended-reading") >= 0:
      result = "recommended-reading";
      break;
    case aPathname[aPathname.length - 1].indexOf("guidelines") >= 0:
      aPathname.pop();
      result = aPathname.join("/");
      break;
    case aPathname.indexOf("finalist") === aPathname.length - 2:
    case aPathname.indexOf("winner") === aPathname.length - 2:
      aPathname.pop();
      aPathname.pop();
      result = aPathname.join("/");
      break;
    case aPathname.indexOf("jury") === aPathname.length - 2:
      aPathname.pop();
      aPathname.pop();
      result = aPathname.join("/");
      break;
    case aPathname.indexOf("staff") === aPathname.length - 2:
      aPathname.pop();
      aPathname.pop();
      result = aPathname.join("/");
      break;
    case aPathname.indexOf("writer") === aPathname.length - 2:
      aPathname.pop();
      aPathname.pop();
      result = aPathname.join("/");
      break;
    case aPathname.indexOf("books") === aPathname.length - 2:
      aPathname.pop();
      aPathname.pop();
      result = aPathname.join("/");
      break;
    default:
      result = pathname;
      break;
  }
  return `${result}-${suffix}`;
};
