import React, { PureComponent } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Triangles from "components/Triangles";
import WipeOverlay from "components/WipeOverlay";
import { renderText } from "utils/renderHelpers";
import posed, { PoseGroup } from "react-pose";
import { sine } from "utils/easing";
import resolveLinkById from "utils/resolveLinkById";

const Nav = posed.nav({
  closed: {
    applyAtEnd: {
      display: "none",
    },
    opacity: 0,
    beforeChildren: false,
    transition: {
      type: `tween`,
      duration: 250,
      ease: sine.in,
    },
  },
  open: {
    applyAtStart: {
      display: "block",
    },
    opacity: 1,
    beforeChildren: true,
    transition: {
      type: "tween",
      duration: 0,
    },
    staggerChildren: 50,
  },
});

const Column = posed.div({
  closed: {
    opacity: 0,
    y: -30,
    transition: {
      opacity: {
        type: "tween",
        duration: 0,
      },
      y: {
        type: "tween",
        duration: 0,
      },
    },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 300,
      delay: 200,
      ease: sine.out,
    },
  },
});

const MobileContainer = posed.div({
  mExit: {
    opacity: 0,
    transition: {
      type: `tween`,
      duration: 250,
      ease: sine.in,
    },
  },
  closed: {
    opacity: 0,
    beforeChildren: false,
    transition: {
      type: `tween`,
      duration: 250,
      ease: sine.in,
    },
  },
  open: {
    opacity: 1,
    y: 0,
    staggerChildren: 50,
    transition: {
      type: "tween",
      duration: 300,
      delay: 200,
      ease: sine.out,
    },
  },
});

const mobileItemConfig = {
  mExit: {
    opacity: 0,
    transition: {
      type: `tween`,
      duration: 250,
      ease: sine.in,
    },
  },
  closed: {
    opacity: 0,
    y: 15,
    transition: {
      type: `tween`,
      duration: 250,
      ease: sine.in,
    },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 300,
      ease: sine.out,
    },
  },
};

const mobileBackConfig = {
  mExit: {
    x: `-100%`,
    transition: {
      type: `tween`,
      duration: 300,
      ease: sine.in,
    },
  },
  closed: {
    x: `-100%`,
    transition: {
      type: `tween`,
      duration: 300,
      ease: sine.in,
    },
  },
  open: {
    x: 0,
    transition: {
      delay: 200,
      type: "tween",
      duration: 400,
      ease: sine.out,
    },
  },
};

const BackButton = posed.button(mobileBackConfig);
const MobileListItem = posed.li(mobileItemConfig);

export default class Menu extends PureComponent {
  static getDerivedStateFromProps(props) {
    return { isMobile: props.isMobile };
  }

  constructor(props) {
    super(props);
    this.state = {
      deep: false,
      deepIndex: -1,
      isMobile: props.isMobile,
    };
  }

  onPoseComplete = () => {
    if (!this.props.open) {
      this.setState({ deep: false });
    }
  };

  backToMain = () => {
    this.setState({ deep: false, deepIndex: -1 });
  };

  goDeep = (idx) => {
    this.setState({ deep: true, deepIndex: idx });
  };

  render() {
    const { styles, className, data, open } = this.props;
    let deepSlice;
    if (this.state.deep) {
      deepSlice = data.body[this.state.deepIndex];
    }
    return (
      <Nav
        id="menu"
        ref={(r) => (this.nav = r)}
        className={`${
          this.state.isMobile ? `${styles.mobile} ` : ""
        }${className}`}
        onPoseComplete={this.onPoseComplete}
        pose={open ? "open" : "closed"}
        initialPose="closed"
      >
        <WipeOverlay theme="black" open={open === true} />
        <Container className={styles.menuContainer}>
          {this.state.isMobile && open ? (
            <PoseGroup
              animateOnMount={true}
              enterPose="open"
              exitPose="mExit"
              preEnterPose="closed"
            >
              <MobileContainer
                key={`container-${this.state.deepIndex}`}
                withParent={false}
              >
                {this.state.deep ? (
                  <React.Fragment>
                    <BackButton
                      initialPose={"closed"}
                      className={styles.mobileBack}
                      onClick={this.backToMain}
                    >
                      <i className="icon">
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </i>
                      {renderText(deepSlice.primary.heading, "span")}
                    </BackButton>
                    <ul className={styles.mobileList}>
                      {deepSlice.primary.headling_link && (
                        <MobileListItem
                          className={styles.mobileItem}
                          initialPose={`closed`}
                        >
                          <Link
                            to={
                              resolveLinkById(
                                deepSlice.primary.headling_link.raw.id
                              ).slug
                            }
                            title={deepSlice.primary.heading.text}
                          >
                            {renderText(deepSlice.primary.heading, `span`)}
                          </Link>
                        </MobileListItem>
                      )}
                      {deepSlice.items.map((item, idx) => {
                        if (!item.link && !item.manual_page_link) {
                          return null;
                        }
                        return (
                          <MobileListItem
                            initialPose={`closed`}
                            key={`mobile-${this.state.deepIndex}-${idx}`}
                            className={styles.mobileItem}
                          >
                            {item.link && item.link.raw ? (
                              <Link
                                to={resolveLinkById(item.link.raw.id).slug}
                                // onClick={hideMenu}
                              >
                                {renderText(
                                  item.link_label &&
                                    item.link_label.text &&
                                    item.link_label.text.length
                                    ? item.link_label
                                    : resolveLinkById(item.link.raw.id).title,
                                  `span`
                                )}
                              </Link>
                            ) : (
                              <Link
                                to={item.manual_page_link}
                                // onClick={hideMenu}
                              >
                                {item.link_label.text}
                              </Link>
                            )}
                          </MobileListItem>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ul>
                      {data.body.map(({ id, primary }, idx) => {
                        return (
                          <MobileListItem
                            initialPose={`closed`}
                            key={id}
                            className={styles.mobileTopLevel}
                          >
                            <button
                              title={primary.heading.text}
                              onClick={() => {
                                this.goDeep(idx);
                              }}
                            >
                              {renderText(primary.heading, `span`)}
                            </button>
                          </MobileListItem>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                )}
              </MobileContainer>
            </PoseGroup>
          ) : (
            <Columns mobile>
              {data.body.map(({ id, primary, items }, idx) => {
                let slug =
                  primary.headling_link && primary.headling_link.raw
                    ? primary.headling_link.raw.slug
                    : null;
                slug = slug.split("-").join("");
                return (
                  <Column
                    key={id}
                    pose={open ? "open" : "closed"}
                    className={`column is-gapless ${styles[`${slug}Column`]} ${
                      styles.menuColumn
                    }`}
                    initialPose={`closed`}
                  >
                    {primary.headling_link ? (
                      <Link
                        to={resolveLinkById(primary.headling_link.raw.id).slug}
                        title={primary.heading.text}
                        className={styles.headingLink}
                      >
                        {renderText(primary.heading, `span`)}
                      </Link>
                    ) : (
                      renderText(primary.heading, `h3`)
                    )}
                    <ul>
                      {items.map((item, idx) => {
                        if (!item.link && !item.manual_page_link) {
                          return null;
                        }
                        return (
                          <li key={`${id}-${idx}`}>
                            {item.link && item.link.raw ? (
                              <Link
                                to={resolveLinkById(item.link.raw.id).slug}
                                title={resolveLinkById(item.link.raw.id).title}
                              >
                                {renderText(
                                  item.link_label &&
                                    item.link_label.text &&
                                    item.link_label.text.length
                                    ? item.link_label
                                    : resolveLinkById(item.link.raw.id).title,
                                  `span`
                                )}
                              </Link>
                            ) : (
                              <Link
                                to={item.manual_page_link}
                                title={item.link_label.text}
                                /*onClick={hideMenu}*/
                              >
                                {item.link_label.text}
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    {idx < data.body.length - 1 && (
                      <figure className={styles.divider} />
                    )}
                  </Column>
                );
              })}
            </Columns>
          )}
        </Container>
        <figure className={styles.triangles}>
          <Triangles
            theme="red"
            animationType={`random`}
            autoReveal={false}
            revealed={open}
            revealDelay={800}
          />
        </figure>
      </Nav>
    );
  }
}
