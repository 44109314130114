// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-author-page-author-page-js": () => import("./../../../src/templates/AuthorPage/AuthorPage.js" /* webpackChunkName: "component---src-templates-author-page-author-page-js" */),
  "component---src-templates-award-page-award-page-js": () => import("./../../../src/templates/AwardPage/AwardPage.js" /* webpackChunkName: "component---src-templates-award-page-award-page-js" */),
  "component---src-templates-event-page-event-page-js": () => import("./../../../src/templates/EventPage/EventPage.js" /* webpackChunkName: "component---src-templates-event-page-event-page-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/LandingPage/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */),
  "component---src-templates-press-page-press-page-js": () => import("./../../../src/templates/PressPage/PressPage.js" /* webpackChunkName: "component---src-templates-press-page-press-page-js" */),
  "component---src-templates-program-page-program-page-js": () => import("./../../../src/templates/ProgramPage/ProgramPage.js" /* webpackChunkName: "component---src-templates-program-page-program-page-js" */),
  "component---src-templates-recco-reading-recco-reading-js": () => import("./../../../src/templates/ReccoReading/ReccoReading.js" /* webpackChunkName: "component---src-templates-recco-reading-recco-reading-js" */),
  "component---src-templates-writers-books-writers-books-js": () => import("./../../../src/templates/WritersBooks/WritersBooks.js" /* webpackChunkName: "component---src-templates-writers-books-writers-books-js" */)
}

