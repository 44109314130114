import React from "react";
import trunc from "trunc-html";
import { Link } from "gatsby";
import truncate from "utils/truncate";
import SplitText from "react-pose-text";
import resolveLinkById from "./resolveLinkById";

export const validateText = obj => {
  if (!obj) {
    return false;
  }
  return obj.text && obj.text.length > 0;
};

export const validateHTML = obj => {
  if (!obj) {
    return false;
  }
  return (
    obj.html &&
    obj.html.length > 0 &&
    obj.html.charAt(0) === "<" &&
    obj.html !== "<p></p>"
  );
};

const getStringFromPrismicObject = obj => {
  if (!obj) {
    return null;
  }
  const str = typeof obj === "string" ? obj : obj.text;
  if (!str) {
    return null;
  }
  return str;
};

const getHTMLFromPrismicObject = obj => {
  if (!obj) {
    return null;
  }
  const str = typeof obj === "string" ? obj : obj.html;
  if (!str) {
    return null;
  }
  return str;
};

export const renderText = (
  obj,
  Tag = "div",
  classes = null,
  props = {},
  truncateWords = 0,
  prefix = null,
  suffix = null,
  isAnimated = false,
  animationProps = {},
  isBookTitle = false,
  isShortStory = false
) => {
  let str = getStringFromPrismicObject(obj);
  if (!str) {
    return null;
  }
  if (truncateWords > 0) {
    str = truncate(str, truncateWords);
  }

  if (prefix) {
    str = `${prefix}${str}`;
  }
  if (suffix) {
    str = `${str}${suffix}`;
  }
  return isAnimated === true ? (
    <Tag
      className={`${classes || ""}${isBookTitle ? ` book-title` : ``}${
        isShortStory ? ` short-story` : ``
      }`}
      {...props}
    >
      <SplitText {...animationProps}>{str}</SplitText>
    </Tag>
  ) : (
    <Tag
      className={`${classes || ""}${isBookTitle ? ` book-title` : ``}${
        isShortStory ? ` short-story` : ``
      }`}
      {...props}
    >
      {str}
    </Tag>
  );
};

export const renderHtml = (
  obj,
  Tag = "div",
  classes = null,
  props = {},
  truncateWords = 0,
  prefix = null,
  suffix = null
) => {
  let htmlStr = getHTMLFromPrismicObject(obj);

  if (!htmlStr) {
    return null;
  }

  if (truncateWords > 0) {
    htmlStr = trunc(htmlStr, truncateWords).html;
  }

  if (prefix) {
    htmlStr = `${prefix}${htmlStr}`;
  }

  if (suffix) {
    htmlStr = `${htmlStr}${suffix}`;
  }

  return (
    <Tag
      className={classes || ""}
      {...props}
      dangerouslySetInnerHTML={{ __html: htmlStr }}
    />
  );
};

export const renderButton = (label, link, classes = null, props = {}) => {
  const labelStr = getStringFromPrismicObject(label);
  if (!labelStr || !link) {
    return null;
  }

  return (
    <Link
      className={classes || ""}
      to={link && (link.url || link)}
      title={labelStr}
      {...props}
    >
      {labelStr}
    </Link>
  );
};

export const PrismicLink = ({
  link,
  label = null,
  anchor,
  className,
  children,
  linkState = null,
  ...props
}) => {
  if (!link || !link.raw) {
    console.error(
      "renderButton:: must provide a link with link.raw from graphql"
    );
    if (link) {
      console.log(link.url);
    }
    return null;
  }
  let labelStr = null;
  if (label) {
    labelStr = getStringFromPrismicObject(label);
  }

  if (!labelStr && !link) {
    return null;
  }

  const linkData = link.raw;
  let url;
  let target;
  let lookupData = resolveLinkById(link.raw.id);
  let documentData =
    link.document && link.document[0] ? link.document[0].data : null;

  if (linkData.link_type === "Document") {
    url = documentData
      ? documentData.slug
      : lookupData
      ? lookupData.slug
      : null;
    if (!url) {
      return null;
    }
    if (anchor) {
      url = `${url}#${anchor}`;
    }
    if (!labelStr && lookupData) {
      labelStr = lookupData.title;
    }
    if (!labelStr && documentData) {
      labelStr = documentData.title.text;
    }

    return (
      <Link
        to={url}
        title={labelStr}
        className={className}
        state={linkState}
        {...props}
      >
        {children || labelStr}
      </Link>
    );
  }
  url = linkData.url || link.url;
  target = props.target || linkData.target || "_self";
  return (
    <a
      href={url}
      title={labelStr}
      className={className}
      {...props}
      target={target}
    >
      {children || labelStr}
    </a>
  );
};
