export default (elementOrId, offset = 0, location = 'top') => {
  const el =
    typeof elementOrId === 'string'
      ? document.getElementById(elementOrId)
      : elementOrId;
  if (!el) {
    console.log('no element');
    return;
  }
  console.log('scrolling to ', el);
  const bounds =
    el === window ? { top: 0, bottom: 0 } : el.getBoundingClientRect();

  const top = Math.round(
    (bounds[location] === undefined ? 0 : bounds[location]) +
      window.pageYOffset +
      offset
  );

  setTimeout(() => {
    window.scroll({
      top,
      left: 0,
      behavior: 'smooth'
    });
  }, 1);
};

export const smoothScrollToTop = () => {
  setTimeout(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, 1);
};
