// import '@babel/polyfill';

import "css/base.scss";

import GlobalEmitter from "utils/GlobalEmitter";
import Layout from "components/Layout";
import React from "react";
import events from "utils/events";
import getTransitionKey from "utils/getTransitionKey";
import { navigate } from "gatsby";
import objectFitImages from "object-fit-images";

window.navigateTo = navigate;

new GlobalEmitter();

export const replaceComponentRenderer = ({ props, loader }) => {
  return React.createElement(props.pageResources.component, {
    ...props,
    loader,
    key: getTransitionKey(props.location.pathname, "page"),
  });
};

export const wrapPageElement = ({ props, element }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  const testImg = document.createElement(`img`);
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    objectFitImages();
    console.log(`👍 Object-fit/Object-position are polyfilled`);
  }
};

export const shouldUpdateScroll = () => {
  return false;
};

// Store the window width
window.actualWidth = window.innerWidth;

// Resize Event
window.addEventListener("resize", () => {
  if (window.innerWidth !== window.actualWidth) {
    window.actualWidth = window.innerWidth;
    GlobalEmitter.emit(events.resize, {
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
});
